import React from 'react';
import styled from '@emotion/styled';
import { mq, isIe } from 'style/variables';
import { contentWrapper } from 'style/mixin';
import sizes from 'style/sizes';
import RangeSlide from '../rangeSlide';
import { useIsSp } from 'util/useIsSp';

type TopicsGroupProps = {
  spslide?: boolean;
};

const ContainerWrapper = styled.div`
  ${mq.onlypc} {
    margin-bottom: ${sizes.margin[81]};
  }
`;

const ContainerWrapperNegative = styled.div`
  margin-bottom: -${sizes.margin[81]};
  ${mq.onlysp} {
    margin-bottom: -${sizes.margin[64]};
  }
`;

const Container = styled.div<TopicsGroupProps>`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  box-sizing: content-box;
  margin-bottom: -${sizes.margin[81]};
  ${mq.onlysp} {
    ${props => !props.spslide && contentWrapper};
    ${props => !props.spslide && `margin-bottom: -${sizes.margin[49]}`};
  }
  & > a {
    ${mq.onlypc} {
      margin-bottom: 98px; // refer to organisms/ProjectCardCol gap, 120px - padding-bottom 22px = 98px
      width: calc((100% - ${sizes.margin[81]} * 2) / 3);
      &:not(:nth-of-type(3n)) {
        margin-right: ${sizes.margin[80]}; // refer to organisms/ProjectCardCol gap
      }

      ${isIe} {
        width: calc((100% - ${sizes.margin[81]} * 2) / 3 - 0.1px);
      }
    }
    ${mq.onlysp} {
      margin-bottom: ${sizes.margin[48]}; // refer to organisms/ProjectCardCol gap
    }
  }
`;

export const TopicsGroup: React.FC<TopicsGroupProps> = props => {
  const isSp = useIsSp();

  if (props.spslide) {
    if (isSp) {
      return (
        <RangeSlide perView={3} middle={true}>
          {props.children}
        </RangeSlide>
      );
    } else {
      return (
        <ContainerWrapperNegative>
          <Container>{props.children}</Container>
        </ContainerWrapperNegative>
      );
    }
  } else {
    return (
      <ContainerWrapper>
        <Container>{props.children}</Container>
      </ContainerWrapper>
    );
  }
};
